<template>
  <v-card flat>
    <v-card-text>
      <h3>ช่องทางการชำระเงิน</h3>
      <hr class="mt-2" />
      <div class="warpper_contentButton">
        <div class="mt-5">
          <v-icon size="50">{{ icons.mdiBank }}</v-icon>
        </div>
        <h3>ไม่พบช่องทางการชำระเงิน</h3>
        <span>เพื่อเป็นช่องทางให้ลูกค้าของคุณชำระค่าสินค้าและบริการ</span>
        <v-dialog transition="dialog-top-transition" width="600">
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-btn color="primary" v-bind="attrs" v-on="on" class="mt-5 buttonCreate_Payment">
                <v-icon>{{ icons.mdiPlus }}</v-icon>
                สร้างช่องทางการชำระเงิน
              </v-btn>
            </div>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <!-- Content import -->
              <CreatePayment />
              <v-card-actions>
                <v-card-text class="d-flex justify-center">
                  <v-btn color="error" outlined @click="dialog.value = false" class="me-3 test">ยกเลิก</v-btn>
                  <v-btn color="primary" @click="dialog.value = false" class="test">สร้าง</v-btn>
                </v-card-text>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
      <h3 class="mt-5">ตรวจสอบสลิป</h3>
      <hr class="mt-2 mb-3" />
      <div class="wapper_CheckSlip">
        <div class="content_inCheckSlip">
          <div class="text_description">
            <p class="mb-0">
              <span class="font-weight-bold">การตรวจสอบสลิปอัตโนมัติผ่าน QR code</span> <br />
              เปิดใช้งานระบบการตรวจสอบสลิปอัตโนมัติผ่าน QR code ของธนาคาร <br />
              <a href="#" class="font-weight-bold colorFontUpgrade">อัพเกรด</a>
              <span class="font_description">
                เพื่อลดงานแอดมินให้ what you want ช่วยตรวจสอบสลิปอัตโนมัติ ผ่าน QR code จากสลิปธนาคาร
              </span>
            </p>
          </div>
          <div class="d-flex">
            <v-icon size="25" class="mt-1 me-2" v-if="upgrade_user">{{ icons.mdiLock }}</v-icon>
            <v-icon size="25" class="mt-1 me-2" v-else>{{ icons.mdiLockOpenVariant }}</v-icon>
            <v-switch hide-details v-model="Upgrade" :disabled="upgrade_user" class="mt-0"></v-switch>
            <!-- test function -->
            <v-dialog transition="dialog-top-transition" width="600">
              <template v-slot:activator="{ on, attrs }">
                <div class="noneUpgrade">
                  <v-btn color="primary" v-bind="attrs" v-on="on" class="mt-5 testFunction" v-if="upgrade_user">
                    Test
                  </v-btn>
                </div>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <!-- Content import -->
                  <UpgradePage />
                  <v-card-text class="d-flex justify-center">
                    <v-btn color="error" outlined @click="dialog.value = false" class="me-3">ปิด</v-btn>
                    <v-btn color="primary" @click="dialog.value = false">อัพเกรด</v-btn>
                  </v-card-text>
                  <!-- <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Close</v-btn>
                  </v-card-actions> -->
                </v-card>
              </template>
            </v-dialog>
          </div>
        </div>
      </div>
      <hr class="mt-5 mb-5" />
      <div class="wapper_CheckSlip">
        <div class="content_inCheckSlip">
          <div class="text_description">
            <p class="mb-0">
              <span class="font-weight-bold">การเปลี่ยนสถานะบิลอัตโนมัติ</span> <br />
              จะทำการเปลี่ยนสถานะบิลจาก 'โอนแล้ว' เป็น 'เตรียมส่ง' ให้อัตโนมัติ เมื่อทำการตรวจสอบสลิปสำเร็จ <br />
              <a href="#" class="font-weight-bold colorFontUpgrade">อัพเกรด</a>
              <span class="font_description">
                เพื่อลดงานแอดมินไม่ต้องเสียเวลาเปลี่ยนสถานะบิลให้ what you want เปลี่ยนให้อัตโนมัติ
              </span>
            </p>
          </div>
          <div class="d-flex">
            <v-icon size="25" class="mt-1 me-2" v-if="upgrade_user">{{ icons.mdiLock }}</v-icon>
            <v-icon size="25" class="mt-1 me-2" v-else>{{ icons.mdiLockOpenVariant }}</v-icon>
            <v-switch hide-details v-model="Upgrade" :disabled="upgrade_user" class="mt-0"></v-switch>
            <v-dialog transition="dialog-top-transition" width="600">
              <template v-slot:activator="{ on, attrs }">
                <div class="noneUpgrade">
                  <v-btn color="primary" v-bind="attrs" v-on="on" class="mt-5 testFunction" v-if="upgrade_user">
                    Test
                  </v-btn>
                </div>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <!-- Content import -->
                  <UpgradePage />
                  <v-card-text class="d-flex justify-center">
                    <v-btn color="error" outlined @click="dialog.value = false" class="me-3">ปิด</v-btn>
                    <v-btn color="primary" @click="dialog.value = false">อัพเกรด</v-btn>
                  </v-card-text>
                </v-card>
              </template>
            </v-dialog>
          </div>
        </div>
      </div>
      <hr class="mt-5 mb-5" />
      <div class="discripttion">
        <p class="mt-0">
          ระบบตรวจสอบสลิปอัตโนมัติให้บริการตรวจสอบโดยตรงกับธนาคาร
          <span class="font-weight-bold">ทาง what you want ไม่รับผิดชอบความเสียหาย</span>
          ที่อาจจะเกิดขึ้นจากการทุจริตประเภทใด ๆ ก็ตาม เช่น การใช้สลิปซ้ำ กรุณาตรวจสอบรายการผ่านทางแอปพลิเคชันของ
          ธนาคารอีกครั้ง
          <a href="https://www.google.com/" target="_blank">เรียนรู้เพิ่มเติม</a>
        </p>
      </div>
      <h3 class="mt-5">รับชำระด้วยบัตรเครดิต</h3>
      <hr class="mt-2 mb-5" />
      <p class="font-weight-bold">
        เปิดใช้งานระบบรับชำระด้วยบัตรเครดิต (Omise Chain) เพื่อให้ร้านค้าใช้งานได้อย่างสะดวกและปลอดภัยยิ่งขึ้น
      </p>
      <div class="openCredit">
        <p class="mb-2 font-weight-bold textHeder">
          วิธีการเปิดใช้งานรับชำระด้วยบัตรเครดิต
        </p>
        <p class="textDesciptionOpenCredit">
          1. <span>สมัครสมาชิก Omise</span> สำหรับเชื่อมกับ what you want โดยเฉพาะ (ไม่สามารถใช้บัญชี Omise
          เดิมที่เปิดก่อนหน้าได้) <br />
          2. <span>กรอกใบสมัคร</span> และส่งเอกสารเพื่อขอใช้งานรับชำระด้วยบัตรเครดิต ทีมงานจะใช้เวลาพิจารณาเอกสารทั้งหมด
          15-20 วันทำการ หลังจากเอกสารผ่านการพิจารณาแล้ว คลิกปุ่ม "เปิดใช้งาน"
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiBank, mdiPlus, mdiLock, mdiLockOpenVariant } from '@mdi/js'
import UpgradePage from '@/views/pages/UpgradePage.vue'
import CreatePayment from '@/views/pages/CreatePayment.vue'

export default {
  components: {
    UpgradePage,
    CreatePayment,
  },
  setup() {
    return {
      icons: {
        mdiBank,
        mdiPlus,
        mdiLock,
        mdiLockOpenVariant,
      },
    }
  },
  data() {
    return {
      Upgrade: false,
      upgrade_user: true,
    }
  },
  updated() {
    if (this.upgrade_user === false) {
      this.Upgrade = true
    }
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.warpper_contentButton {
  text-align: center;
  display: grid;
  justify-content: center;
  align-items: center;
  line-height: 1.7rem;
}
.buttonCreate_Payment {
  width: 60%;
}
.wapper_CheckSlip {
  display: grid;
}
.content_inCheckSlip {
  display: flex;
  justify-content: space-between;
}
.text_description {
  width: 70%;
}
.colorFontUpgrade {
  color: #8dbc8a;
  text-decoration: none;
}
.font_description {
  font-weight: 600;
  color: #928d99;
}
.discripttion {
  width: 100%;
  background-color: #fafafa;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-weight: 500;
}
.discripttion a {
  text-decoration: none;
}
.openCredit {
  width: 100%;
  background-color: #e0f2fe;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-weight: 500;
}
.openCredit a {
  text-decoration: none;
}
.openCredit .textHeder {
  color: #0b4b6e;
}
.openCredit .textDesciptionOpenCredit {
  color: #0569a2;
}
.textDesciptionOpenCredit span {
  color: #8dbc8a;
}
.noneUpgrade {
  position: relative;
}
.testFunction {
  position: absolute;
  transform: translateX(-100%);
  top: -20px;
  opacity: 0;
}
.test {
  width: 53%;
}
</style>
