<template>
  <v-card flat>
    <v-card-text>
      <!-- text header -->
      <h3 class="mb-3">ยืนยันตัวตน</h3>
      <div class="d-flex mb-3">
        <v-icon size="15" class="icon">{{ icons.mdiCheckCircle }}</v-icon>
        <p class="mb-0">ยืนยันตัวตน</p>
        <v-icon size="15" class="icon">{{ icons.mdiCheckCircle }}</v-icon>
        <p class="mb-0">การรับชำระ</p>
      </div>
      <p class="mb-5">
        ให้คุณปิดการขายได้เร็วขึ้น แถมเพิ่มความสบายใจให้ลูกค้าในการซื้อสินค้าผ่านหน้าร้าน
        จึงขอเป็นส่วนหนึ่งในการช่วยตรวจสอบความน่าเชื่อถือของแม่ค้า เพียงแค่กรอกข้อมูลร้านค้า
        และเพิ่มสำเนาบัตรประชาชนและสำเนาหน้าสมุดบัญชีเพื่อเปิดใช้บริการ
      </p>
      <hr class="mt-5 mb-5" />

      <!-- ประเภทร้านค้า -->
      <v-row>
        <v-col md="6" cols="12">
          <p class="font-weight-bold warpper_text">ประเภทร้านค้า <span class="font-weight-regular">(จำเป็น)</span></p>
        </v-col>
        <v-col md="6" cols="12">
          <div class="warpper_input">
            <v-select
              dense
              outlined
              hide-details
              label="ประเภทร้านค้า"
              :items="typeStore"
              class="select__typeStore"
            ></v-select>
          </div>
        </v-col>
      </v-row>
      <hr class="mt-5 mb-5" />

      <!-- บัญชีรับเงิน -->
      <v-row>
        <v-col md="6" cols="12">
          <p class="font-weight-bold warpper_text">บัญชีรับเงิน <span class="font-weight-regular">(จำเป็น)</span></p>
        </v-col>
        <v-col md="6" cols="12">
          <div class="warpper_input">
            <v-dialog transition="dialog-top-transition" width="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn text color="primary" v-bind="attrs" v-on="on" class="me-2">เพิ่มบัญชี</v-btn>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <!-- Content import -->
                  <v-card-text>
                    <PayMentMethod />
                    <v-btn color="primary" @click="dialog.value = false" class="me-2">เปิดใช้งาน</v-btn>
                    <v-btn color="error" outlined @click="dialog.value = false">เรียนรู้เพิ่มเติม</v-btn>
                  </v-card-text>
                  <!-- <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Close</v-btn>
                  </v-card-actions> -->
                </v-card>
              </template>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
      <hr class="mt-5 mb-5" />

      <!-- ชนิดของธุรกิจ -->
      <v-row>
        <v-col md="6" cols="12">
          <p class="font-weight-bold warpper_text">ชนิดของธุรกิจ <span class="font-weight-regular">(จำเป็น)</span></p>
        </v-col>
        <v-col md="6" cols="12">
          <div class="warpper_input">
            <v-text-field
              v-model="typeBusiness"
              outlined
              dense
              hide-details
              label="ชนิดของธุรกิจ"
              class="input_confirmPage"
              placeholder="ค้าปลีก"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <hr class="mt-5 mb-5" />

      <!-- ชนิดของธุรกิจ -->
      <v-row>
        <v-col md="6" cols="12">
          <p class="font-weight-bold warpper_text">
            ชนิดของสินค้าและบริการ <span class="font-weight-regular">(จำเป็น)</span>
          </p>
        </v-col>
        <v-col md="6" cols="12">
          <div class="warpper_input">
            <v-text-field
              v-model="typeProduction"
              outlined
              dense
              hide-details
              label="ชนิดของสินค้าและบริการ"
              class="input_confirmPage"
              placeholder="เสื้อผ้า"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <hr class="mt-5 mb-5" />

      <!-- ที่อยู่เว็บไซต์ของหน้าร้านหลัก -->
      <v-row>
        <v-col md="6" cols="12">
          <p class="font-weight-bold warpper_text">
            ที่อยู่เว็บไซต์ของหน้าร้านหลัก
          </p>
        </v-col>
        <v-col md="6" cols="12">
          <div class="warpper_input">
            <v-text-field
              v-model="websiteStore"
              outlined
              dense
              hide-details
              label="ที่อยู่เว็บไซต์"
              class="input_confirmPage"
              placeholder="https://mywebsite.com"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <hr class="mt-5 mb-5" />

      <!-- นโยบายการส่งและคืนสินค้า -->
      <v-row>
        <v-col md="6" cols="12">
          <p class="font-weight-bold warpper_text">
            นโยบายการส่งและคืนสินค้า <span class="font-weight-regular">(จำเป็น)</span>
          </p>
        </v-col>
        <v-col md="6" cols="12">
          <div class="warpper_input">
            <v-textarea
              label="นโยบายการส่งและคืนสินค้า"
              auto-grow
              outlined
              rows="1"
              row-height="15"
              class="mt-2 input_confirmPage"
              placeholder="ส่งภายใน 3 วันนับจากวันชำระเงิน"
            ></v-textarea>
          </div>
        </v-col>
      </v-row>
      <hr class="mb-5" />

      <!-- เอกสารเพิ่มเติม -->
      <v-row>
        <v-col md="6" cols="12">
          <p class="font-weight-bold warpper_text">เอกสารเพิ่มเติม <span class="font-weight-regular">(จำเป็น)</span></p>
        </v-col>
        <v-col md="6" cols="12">
          <div class="warpper_input">
            <v-select
              dense
              outlined
              hide-details
              label="เอกสารเพิ่มเติม"
              :items="ducuments"
              class="select__typeStore"
            ></v-select>
          </div>
        </v-col>
      </v-row>
      <hr class="mt-5 mb-5" />
      <p>
        เพื่อให้ลูกค้าเกิดความมั่นใจในการชำระเงินผ่านบิลออนไลน์ และซื้อสินค้าผ่านหน้าร้านทางเรา
        จึงเป็นส่วนหนึ่งในการช่วยตรวจสอบความน่าเชื่อถือของแม่ค้า
      </p>
      <v-row>
        <v-col md="4" cols="12">
          <div class="verify">
            <v-icon size="65">{{ icons.mdiImageMultiple }}</v-icon>
            <p class="mt-3">บัตรประชาชน</p>
            <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF" class="uploadFile" />
          </div>
        </v-col>
        <v-col md="4" cols="12">
          <div class="verify">
            <v-icon size="65">{{ icons.mdiImageMultiple }}</v-icon>
            <p class="mt-3">สมุดบัญชีหน้าที่มีชื่อคุณ</p>
            <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF" class="uploadFile" />
          </div>
        </v-col>
        <v-col md="4" cols="12">
          <div class="verify">
            <v-icon size="65">{{ icons.mdiImageMultiple }}</v-icon>
            <p class="mt-3 mb-0">รายการากถอนในบัญชีเงินฝาก</p>
            <span>(ช่วยประกอบในการพิจารณา)</span>
            <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF" class="uploadFile" />
          </div>
        </v-col>
      </v-row>
      <hr class="mt-5 mb-5" />

      <!-- ร้านค้ายืนยันตัวตน -->
      <v-row>
        <v-col md="6" cols="12">
          <p class="font-weight-bold warpper_text">
            ร้านค้ายืนยันตัวตน เพิ่มความมั่นใจให้ลูกค้า
          </p>
        </v-col>
        <v-col md="6" cols="12">
          <div class="warpper_input">
            <v-btn color="primary" v-model="sentDocument" :disabled="sentDocument">
              ส่งเอกสาร
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <hr class="mb-5 mt-5" />

      <!-- รับชำระเงินผ่านบัตรเครดิต -->
      <v-row>
        <v-col md="6" cols="12">
          <p class="font-weight-bold">
            รับชำระเงินผ่านบัตรเครดิต
          </p>
        </v-col>
        <v-col md="6" cols="12">
          <div class="warpper_input">
            <span>ปิดรับสมัครชั่วคราว</span>
          </div>
        </v-col>
      </v-row>
      <hr class="mb-5 mt-2" />

      <!-- เงื่อนไข -->
      <v-row>
        <v-col md="6" cols="12">
          <p class="text_infoPrimary">
            - สำเนาบัตรประชาชนและสำเนาหน้าสมุดบัญชีที่คุณเปิดใช้บริการจะต้องมีข้อมูลตรงกัน <br />
            - ทางผู้ให้บริการจะใช้เวลาตรวจสอบเอกสาร ไม่เกิน 15 วันทำการ <br />
            - เมื่อส่งเอกสารรับชำระ ระบบจะทำการส่งเอกสารยืนยันตัวตนให้อัตโนมัติ <br />
            <span class="text_info">
              - หากคุณไม่ผ่านการยืนยันตัวตนการรับชำระด้วยบัตรเครดิตตั้งแต่วันที่ 1-15 มิถุนายน 2563 กรุณา
              <a href="https://www.google.com/" target="_blank">ติดต่อทีมงาน</a>เพื่อส่งเอกสารอีกครั้ง</span
            >
          </p>
        </v-col>
        <v-col md="6" cols="12">
          <div class="warpper_textInfo">
            <a href="https://www.google.com/" target="_blank">เงื่อนไขการให้บริการ</a
            ><span class="me-1 ml-1">หรือ</span>
            <a href="https://www.google.com/" target="_blank">เรียนรู้เพิ่มเติม</a>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheckCircle, mdiImageMultiple } from '@mdi/js'
import PayMentMethod from '@/views/pages/Payment-Method.vue'

export default {
  components: {
    PayMentMethod,
  },
  setup() {
    const typeStore = ['บุคคล', 'นิติบุคคล']
    const ducuments = [
      'หนังสือรับรอง อ.ย. สำหรับสินค้าประเภทอาหารเสริมและบำรุงความงาม',
      'หนังสือแต่งตั้งตัวแทนจำหน่าย สำหรับสินค้าลิขสิทธิ์',
      'หนังสืออนุญาตก่อสร้าง สำหรับกิจการประเภทห้องพัก หรือห้องเช่า',
      'หนังสืออนุญาตประกอบวิชาชีพค้าข้าง สำหรับสินค้าประเภทข้าว',
      'หนังสืออนุญาตเป็นตัวแทน / นายหน้า สำหรับนายหน้าประกันชีวิต',
      'หนังสืออนุญาตนำเข้า สำหรับสินค้าควบคุมการนำเข้า',
      'หนังสืออนุญาตประกอบกิจการนำเที่ยว สำหรับกิจการประเภททัวร์',
      'หนังสือสัญญาส่วนลดคู่ค้า สำหรับดีล หรือส่วนลดพิเศษ',
    ]
    const sentDocument = Boolean

    return {
      icons: {
        mdiCheckCircle,
        mdiImageMultiple,
      },
      typeStore,
      ducuments,
      sentDocument,
    }
  },
  data() {
    return {
      typeBusiness: '',
      typeProduction: '',
    }
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.icon {
  margin-top: 2px;
}
.warpper_input {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.warpper_textInfo {
  display: flex;
  justify-content: end;
}
.warpper_textInfo a {
  text-decoration: none;
}
.warpper_input .select__typeStore {
  width: 60%;
}
.warpper_text {
  transform: translateY(50%);
}
.warpper_input a {
  text-decoration: none;
  transform: translateY(50%);
}
.warpper_input span {
  color: #888888;
}
.input_confirmPage {
  width: 60%;
}
.verify {
  background-color: #f6f6f6;
  width: 100%;
  height: 18rem;
  border: 2px dashed #cccccc;
  border-radius: 15px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.verify:hover {
  background-color: #dddddd;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.uploadFile {
  position: absolute;
  top: 0;
  background-color: red;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.text_infoPrimary {
  font-size: 12px;
  line-height: 1.5rem;
}
.text_info {
  color: red;
  font-size: 12px;
}
.text_info a {
  color: red;
  font-weight: 700;
}
</style>
