<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-breadcrumbs :items="items" class="pb-0">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <DecorateStore />
      </v-tab-item>

      <v-tab-item>
        <WelcomeMessage />
      </v-tab-item>

      <v-tab-item>
        <Confirm />
      </v-tab-item>

      <v-tab-item>
        <Coupon />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiStorefront, mdiMessage, mdiAccountCheck, mdiTicketPercent } from '@mdi/js'
import { ref } from '@vue/composition-api'

// demos
import DecorateStore from './DecorateStore.vue'
import WelcomeMessage from './WelcomeMessage.vue'
import Confirm from './Confirm.vue'
import Coupon from './Coupon.vue'

export default {
  components: {
    DecorateStore,
    WelcomeMessage,
    Confirm,
    Coupon,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'ตกแต่งหน้าร้านค้า', icon: mdiStorefront },
      { title: 'ข้อความต้อนรับ', icon: mdiMessage },
      { title: 'ยืนยันตัวตน', icon: mdiAccountCheck },
      { title: 'คูปองสินค้า', icon: mdiTicketPercent },
    ]

    // account settings data
    const accountSettingData = {
      account: {
        avatarImg: require('@/assets/images/avatars/1.png'),
        username: 'whatyouwantshop',
        name: 'whatyouwantshop',
        email: 'whatyouwantshop@example.com',
        role: 'Admin',
        status: 'Active',
        company: 'Google.inc',
      },
      information: {
        bio: '',
        birthday: 'February 22, 1995',
        phone: '09461075555',
        website: 'https://whatyouwantshop.com/',
        country: 'USA',
        languages: ['English', 'Spanish'],
        gender: 'male',
      },
    }

    return {
      tab,
      tabs,
      accountSettingData,
      icons: {
        mdiStorefront,
        mdiMessage,
        mdiAccountCheck,
        mdiTicketPercent,
      },
    }
  },
  data: () => ({
    items: [
      {
        text: 'สินค้า',
        disabled: false,
        href: 'product',
      },
      {
        text: 'หน้าร้านออนไลน์',
        disabled: true,
        href: 'storefront',
      },
    ],
  }),
  methods: {
    clickitem() {
      this.clicktest = !this.clicktest
    },
  },
}
</script>

<style scoped></style>
