var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('h2',{staticClass:"mb-3"},[_vm._v("เพิ่มช่องทางการชำระเงิน")]),_c('hr'),_c('h4',{staticClass:"mt-3 mb-3"},[_vm._v("ช่องทางการชำระ")]),_c('v-select',{attrs:{"outlined":"","dense":"","hide-details":"","label":"กรุณาเลือก","items":[
        'ธนาคารกสิกรไทย',
        'ธนาคารทหารไทยธนชาต',
        'ธนาคารไทยพาณิชย์',
        'ธนาคารกรุงเทพ',
        'ธนาคารกรุงไทย',
        'ธนาคารกรุงศรียุธยา',
        'ธนาคารทหารไทย',
        'ธนาคารยูโอบี',
        'ธนาคารออมสิน',
        'ธนาคารธนชาติ',
        'ธนาคารเกียรตินาคินภัทร',
        'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
        'ธนาคารซีไอเอ็มบีไทย',
        'ธนาคารทิสโก้',
        'ธนาคารแลน์ แอน์ เฮาส์ เพื่อรายย่อย',
        'ธนาคารอาคารสงเคราะห์',
        'ธนาคารอิสมลามแห่งประเทศไทย',
        'ธนาคารเพื่อการเกษตร และสหกรณ์การเกษตร',
        'พร้อมเพย์',
        'ทรูมันนี่',
        'แม่มณี',
        'ปิ๊บจัง',
        'ShopeePay',
        'Rabbit LINE Pay',
        'Paypal',
        'เงินสด' ]}}),_c('hr',{staticClass:"mt-5 mb-3"}),_c('h4',[_vm._v("เลขที่บัญชี")]),_c('v-text-field',{staticClass:"mt-2",attrs:{"outlined":"","dense":"","hide-details":"","label":"เลขที่บัญชี","placeholder":"1234567890"},model:{value:(_vm.account_number),callback:function ($$v) {_vm.account_number=$$v},expression:"account_number"}}),_c('hr',{staticClass:"mt-5 mb-3"}),_c('h4',[_vm._v("ชื่อบัญชี")]),_c('v-text-field',{staticClass:"mt-2",attrs:{"outlined":"","dense":"","hide-details":"","label":"ชื่อบัญชี","placeholder":"บัญชีนี้รวย"},model:{value:(_vm.nameAccounr),callback:function ($$v) {_vm.nameAccounr=$$v},expression:"nameAccounr"}}),_c('hr',{staticClass:"mt-5 mb-3"}),_c('h4',{staticClass:"mb-3"},[_vm._v("รูป QR Code")]),_c('div',{staticClass:"verify"},[_c('v-icon',{attrs:{"size":"65"}},[_vm._v(_vm._s(_vm.icons.mdiImageMultiple))]),_c('p',{staticClass:"mt-2 mb-1 font-weight-bold textSelecteFile"},[_vm._v("เลือกไฟล์")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("PNG, JPG or JPEG")]),_c('input',{ref:"refInputEl",staticClass:"uploadFile",attrs:{"type":"file","accept":".jpeg,.png,.jpg,GIF"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }