<template>
  <v-card flat>
    <v-card-text>
      <!-- test header -->
      <h2>ข้อความต้อนรับสำหรับหน้าร้านค้าออนไลน์</h2>
      <p>
        เพื่อสร้างความประทับใจให้กับลูกค้าของคุณเมื่อเริ่มต้นใช้งานหน้าร้าน
        โดยนอกจากนี้คุณยังสามารถใช้ในการโปรโมทสินค้าหรือโปรโมชั่นได้อีกด้วย
      </p>

      <!-- รูปแบบที่ใช้ในการแสดง -->
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <h3>รูปแบบที่ใช้ในการแสดง</h3>
        </v-col>
        <v-col md="8" cols="12">
          <v-select
            dense
            outlined
            hide-details
            label="รูปแบบที่ใช้ในการแสดง"
            :items="typeShow"
            class="select__typeShow"
          ></v-select>
        </v-col>
      </v-row>
      <hr class="mt-10 mb-10" />

      <!-- ข้อความที่แสดง -->
      <v-row>
        <v-col md="4" cols="12">
          <h3>ข้อความที่แสดง</h3>
        </v-col>
        <v-col md="8" cols="12">
          <v-text-field
            v-model="textShow"
            outlined
            dense
            hide-details
            label="ข้อความที่แสดง"
            class="input_textShow"
            placeholder="ยินดีต้อนรับ เชิญเลือกซื้อสินค้าคุณภาพได้ตามสบาย"
            maxlength="70"
          ></v-text-field>
          <p class="mt-2"><span class="symbol_alert">*</span> จำกัดข้อความไม่เกิน 70 ตัวอักษร</p>
        </v-col>
      </v-row>
      <hr class="mt-2 mb-10" />

      <!-- ขอความบนปุ่ม -->
      <v-row>
        <v-col md="4" cols="12">
          <h3>ข้อความบนปุ่ม</h3>
        </v-col>
        <v-col md="8" cols="12">
          <v-text-field
            v-model="textOnButton"
            outlined
            dense
            hide-details
            label="ข้อความบนปุ่ม"
            class="input_textShow"
            placeholder="กดเลย !"
            maxlength="30"
          ></v-text-field>
          <p class="mt-2"><span class="symbol_alert">*</span> จำกัดข้อความไม่เกิน 30 ตัวอักษร</p>
        </v-col>
      </v-row>
      <hr class="mt-2 mb-10" />

      <!-- URL Website -->
      <v-row>
        <v-col md="4" cols="12">
          <h3>URL (ที่อยู่เว็บไซต์) สำหรับปุ่ม</h3>
        </v-col>
        <v-col md="8" cols="12">
          <v-text-field
            v-model="urlWebsite"
            outlined
            dense
            hide-details
            label="URL Website"
            class="input_textShow"
            placeholder="URL"
          ></v-text-field>
          <p class="mt-2"><span class="symbol_alert">*</span> เว้นว่าง หากไม่มีสินค้าที่ต้องการนำเสนอเป็นพิเศษ</p>
        </v-col>
      </v-row>
      <hr class="mt-2 mb-10" />

      <!-- Button Section -->
      <div class="d-flex justify-center">
        <v-dialog transition="dialog-top-transition" max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              outlined
              v-model="disableBtn"
              class="me-2 uploadFile"
              :disabled="disableBtn"
              v-bind="attrs"
              v-on="on"
            >
              ตัวอย่าง
            </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="primary" dark>ตัวอย่างข้อความที่แสดง</v-toolbar>
              <v-card-text>
                <div class="pa-12">
                  <p class="simple_text">{{ textShow }}</p>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
        <v-btn color="primary" v-model="disableBtn" class="uploadFile" :disabled="disableBtn">
          บันทึก
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  updated() {
    if (this.textShow !== '') {
      this.disableBtn = false
    } else if (this.textShow === '') {
      this.disableBtn = true
    }
  },
  setup() {
    const typeShow = ['ตัวอักษร', 'รูปภาพ']

    return {
      typeShow,
    }
  },
  data() {
    return {
      textShow: '',
      textOnButton: '',
      urlWebsite: '',
      disableBtn: true,
    }
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.symbol_alert {
  color: red;
}
.select__typeShow {
  width: 40%;
}
.input_textShow {
  width: 60%;
}
.simple_text {
  font-size: 20px;
  font-weight: 600;
}
</style>
