<template>
  <v-card flat>
    <v-card-text>
      <!-- รูปโปรไฟล์ร้านค้า (ต้องมีรูปอัพโหลดเข้ามาถึงจะกดปุ่ม บันทึกได้) -->
      <v-row>
        <v-col md="4" cols="12">
          <h3 class="text__primary">รูปโปรไฟล์ร้านค้า</h3>
          <div class="warpper__uploadFile">
            <v-btn color="primary" v-model="uploadFile" class="uploadFile">
              <v-icon class="me-2">{{ icons.mdiCloudUpload }}</v-icon>
              อัพโหลดรูปโลโก้ร้าน
            </v-btn>
            <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF" class="btn__uploadFile" />
            <p class="mt-2">ขนาดรูปที่แนะนำ 180 x 180 px</p>
          </div>
        </v-col>
        <v-col md="8" cols="12">
          <div class="warpper_imageUploadFile">
            <img src="../../../assets/images/other/laptop-2298286.svg" alt="laptop" class="image_content" />
            <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF" class="image__uploadFile_primary" />
          </div>
          <p class="mt-2">ติดโลโก้เพื่อเพิ่มความน่าเชื่อถือของร้าน</p>
        </v-col>
      </v-row>
      <hr class="mb-5" />

      <!-- รูปปกร้านค้า (ต้องมีรูปอัพโหลดเข้ามาถึงจะกดปุ่ม บันทึกได้) -->
      <v-row>
        <v-col md="4" cols="12">
          <h3 class="text__primary">รูปปกร้านค้า</h3>
          <div class="warpper__uploadFile">
            <v-btn color="primary" v-model="uploadFile" class="uploadFile">
              <v-icon class="me-2">{{ icons.mdiCloudUpload }}</v-icon>
              อัพโหลดรูปโลโก้ร้าน
            </v-btn>
            <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF" class="btn__uploadFile" />
            <p class="mt-2">ขนาดรูปที่แนะนำ 1100 x 400 px</p>
          </div>
        </v-col>
        <v-col md="8" cols="12">
          <div class="warpper_imageUploadFile">
            <div class="cover_background"></div>
            <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF" class="image__uploadFile_secondary" />
          </div>
          <p class="mt-4">ติดโลโก้เพื่อเพิ่มความน่าเชื่อถือของร้าน</p>
        </v-col>
      </v-row>
      <hr class="mb-5" />

      <!-- ข้อมูลร้านค้า -->
      <v-row>
        <v-col md="4" cols="12">
          <h3 class="text__primary">ข้อมูลร้านค้า</h3>
        </v-col>
        <v-col md="8" cols="12">
          <v-textarea
            label="ข้อมูลร้านค้า"
            auto-grow
            outlined
            rows="1"
            row-height="15"
            class="mt-2 width_textarea"
          ></v-textarea>
        </v-col>
      </v-row>
      <hr class="mb-5" />

      <!-- Google Analytics -->
      <v-row>
        <v-col md="4" cols="12" class="wrapper_text_google">
          <h3 class="text__primary">Google Analytics</h3>
        </v-col>
        <v-col md="8" cols="12">
          <v-text-field
            v-model="google_analytics"
            outlined
            dense
            hide-details
            label="Google Analytics"
            class="mt-2 input_field_primary"
            placeholder="UA-12345678-9"
          ></v-text-field>
        </v-col>
      </v-row>
      <hr class="mt-7 mb-5" />

      <!-- Facebook Pixel -->
      <v-row>
        <v-col md="4" cols="12" class="wrapper_text_google">
          <h3 class="text__primary">Facebook Pixel</h3>
        </v-col>
        <v-col md="8" cols="12">
          <v-text-field
            v-model="facebook_pixel"
            outlined
            dense
            hide-details
            label="Facebook Pixel"
            class="mt-2 input_field_primary"
            placeholder="1234567890"
          ></v-text-field>
        </v-col>
      </v-row>
      <hr class="mt-7 mb-5" />

      <!-- Custom Tags -->
      <v-row>
        <v-col md="4" cols="12">
          <h3 class="text__primary">Custom Tags</h3>
        </v-col>
        <v-col md="8" cols="12">
          <v-text-field
            v-model="custom_tags"
            outlined
            dense
            hide-details
            label="Custom Tags"
            class="mt-2 input_field_secondary"
            placeholder="<meta name='facebook-domain-verification' content='abcdefghijklmnopqrstuvwxyz'"
          ></v-text-field>
          <p class="mt-2">
            <i>สามารถยืนยันโดเมน โดยนำ meta-tag จาก Facebook มาวางได้ที่นี่</i>
            <a href="https://www.google.com/" target="_blank" class="text_link">เรียนรู้เพิ่มเติม</a>
          </p>
        </v-col>
      </v-row>
      <hr class="mb-5" />

      <!-- Facebook chat widget -->
      <v-row>
        <v-col md="4" cols="12">
          <h3 class="text__primary">Facebook Chat Widget</h3>
          <p>สำหรับร้านค้าที่เชื่อมต่อกับ Facebook page</p>
        </v-col>
        <v-col md="8" cols="12">
          <h4>สามารถเปิดใช้งานโดยเพิ่ม ที่อยู่เว็บไซต์ของหน้าร้าน ในหน้าตั้งค่าของ Facebook page</h4>
          <a href="https://www.google.com/" target="_blank" class="text_linkFB">เรียนรู้เพิ่มเติม</a>
        </v-col>
      </v-row>
      <hr class="mb-5" />
      <div class="d-flex justify-center">
        <v-btn color="primary" v-model="save" :disabled="status_submit">
          บันทึก
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCloudUpload } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiCloudUpload,
      },
    }
  },
  data() {
    return {
      status_submit: true,
    }
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.warpper__uploadFile {
  position: relative;
  top: 40%;
  transform: translateY(-50%);
  text-align: center;
}
.uploadFile {
  width: 100%;
}
.btn__uploadFile {
  position: absolute;
  left: 0;
  height: 2.5rem;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}
.image_content {
  background-color: #78a2cc;
  width: 15.25rem;
  border-radius: 15px;
  border: 2px solid white;
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.5);
}
.warpper_imageUploadFile {
  position: relative;
}
.image__uploadFile_primary {
  position: absolute;
  left: 0;
  width: 15.25rem;
  height: 15.25rem;
  cursor: pointer;
  opacity: 0;
}
.image__uploadFile_secondary {
  position: absolute;
  left: 0;
  top: 0;
  width: 30rem;
  height: 15.25rem;
  cursor: pointer;
  opacity: 0;
}
.cover_background {
  width: 30rem;
  height: 15.25rem;
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  border: 2px solid white;
  background-color: #78a2cc;
}
.wrapper_text_google {
  display: grid;
  align-items: center;
}
.width_textarea {
  width: 30rem;
  height: 5rem;
}
.input_field_primary {
  width: 50%;
}
.input_field_secondary {
  width: 70%;
}
.text_link {
  text-decoration: none;
  margin-left: 0.5rem;
}
.text_linkFB {
  text-decoration: none;
}
</style>
