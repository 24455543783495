<template>
  <v-card flat>
    <v-card-text>
      <h2 class="mb-3">เพิ่มช่องทางการชำระเงิน</h2>
      <hr />
      <h4 class="mt-3 mb-3">ช่องทางการชำระ</h4>
      <v-select
        outlined
        dense
        hide-details
        label="กรุณาเลือก"
        :items="[
          'ธนาคารกสิกรไทย',
          'ธนาคารทหารไทยธนชาต',
          'ธนาคารไทยพาณิชย์',
          'ธนาคารกรุงเทพ',
          'ธนาคารกรุงไทย',
          'ธนาคารกรุงศรียุธยา',
          'ธนาคารทหารไทย',
          'ธนาคารยูโอบี',
          'ธนาคารออมสิน',
          'ธนาคารธนชาติ',
          'ธนาคารเกียรตินาคินภัทร',
          'ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)',
          'ธนาคารซีไอเอ็มบีไทย',
          'ธนาคารทิสโก้',
          'ธนาคารแลน์ แอน์ เฮาส์ เพื่อรายย่อย',
          'ธนาคารอาคารสงเคราะห์',
          'ธนาคารอิสมลามแห่งประเทศไทย',
          'ธนาคารเพื่อการเกษตร และสหกรณ์การเกษตร',
          'พร้อมเพย์',
          'ทรูมันนี่',
          'แม่มณี',
          'ปิ๊บจัง',
          'ShopeePay',
          'Rabbit LINE Pay',
          'Paypal',
          'เงินสด',
        ]"
      ></v-select>
      <hr class="mt-5 mb-3" />
      <h4>เลขที่บัญชี</h4>
      <v-text-field
        v-model="account_number"
        outlined
        dense
        hide-details
        label="เลขที่บัญชี"
        class="mt-2"
        placeholder="1234567890"
      ></v-text-field>
      <hr class="mt-5 mb-3" />
      <h4>ชื่อบัญชี</h4>
      <v-text-field
        v-model="nameAccounr"
        outlined
        dense
        hide-details
        label="ชื่อบัญชี"
        class="mt-2"
        placeholder="บัญชีนี้รวย"
      ></v-text-field>
      <hr class="mt-5 mb-3" />
      <h4 class="mb-3">รูป QR Code</h4>
      <div class="verify">
        <v-icon size="65">{{ icons.mdiImageMultiple }}</v-icon>
        <p class="mt-2 mb-1 font-weight-bold textSelecteFile">เลือกไฟล์</p>
        <p class="font-weight-bold">PNG, JPG or JPEG</p>
        <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF" class="uploadFile" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiImageMultiple } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiImageMultiple,
      },
    }
  },
  data() {
    return {
      account_number: '',
      nameAccounr: '',
    }
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.textSelecteFile {
  color: #8dbc8a;
  font-size: 16px;
}
.verify {
  background-color: #f6f6f6;
  width: 100%;
  height: 18rem;
  border: 2px dashed #cccccc;
  border-radius: 15px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.verify:hover {
  background-color: #dddddd;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.uploadFile {
  position: absolute;
  top: 0;
  background-color: red;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
</style>
