<template>
  <v-card flat>
    <v-card-text>
      <div class="text-center">
        <v-icon size="100" color="primary" class="mb-4">{{ icons.mdiInformation }}</v-icon>
        <h2 class="mb-2">อัพเกรดแพ็กเกจเพื่อใช้งาน</h2>
        <p>แพ็กเกจปัจจุบันของคุณไม่สามารถใช้ฟีเจอร์นี้ได้ กรุณาอัพแพ็กเกจเพื่อใช้งาน</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiInformation } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiInformation,
      },
    }
  },
}
</script>

<style scoped></style>
