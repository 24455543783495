<template>
  <v-card flat>
    <v-card-text>
      <div class="wapper_contentAddCoupon">
        <div>
          <h3>คูปองส่วนลด</h3>
          <p class="mt-1 mb-0">
            คุณสามารถสร้างคูปองส่วนลดได้ง่ายๆ เพื่อกระตุ้นยอดขาย และดึงดูดความสนใจลูกค้าใหม่ๆ ตลอดเวลา
          </p>
        </div>
        <div>
          <v-dialog transition="dialog-top-transition" width="700">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" class="me-2">เพิ่มคูปอง</v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="primary" dark>เพิ่มคูปอง</v-toolbar>
                <v-card-text class="mt-5">
                  <v-row>
                    <v-col md="6" cols="12">
                      <!-- ชื่อคูปองส่วนลด -->
                      <label>ชื่อคูปองส่วนลด</label>
                      <v-text-field
                        v-model="name_coupon"
                        outlined
                        dense
                        hide-details
                        label="ชื่อคูปองส่วนลด"
                        class="mt-2"
                      ></v-text-field>
                    </v-col>
                    <!-- รหัสคูปอง -->
                    <v-col md="6" cols="12">
                      <div class="wrapper_contentRandom">
                        <label class="mb-0">รหัสคูปอง</label>
                        <!-- <v-btn text @click="funtionRamdomNumber()">สุ่มรหัสคูปอง</v-btn> -->
                        <span @click="funtionRamdomNumber()" class="clickRamdomCode">สุ่มรหัสคูปอง</span>
                      </div>
                      <v-text-field
                        v-model="randomNumber"
                        outlined
                        dense
                        hide-details
                        label="รหัสคูปอง"
                        class="mt-2"
                      ></v-text-field>
                    </v-col>
                    <!-- ส่วนลด -->
                    <v-col md="6" cols="12">
                      <div class="d-flex">
                        <label class="mt-1 text_discount">ส่วนลด</label>
                        <v-radio-group v-model="row" hide-details row class="mt-0">
                          <v-radio label="บาท" value="discount-1"></v-radio>
                          <v-radio label="เปอร์เซ็นต์(%)" value="discount-2"></v-radio>
                        </v-radio-group>
                      </div>
                      <v-text-field
                        v-model="discount"
                        outlined
                        dense
                        hide-details
                        label="ส่วนลด"
                        class="mt-2"
                      ></v-text-field>
                    </v-col>
                    <!-- ยอดสั่งซื้อขั้นต่ำ -->
                    <v-col md="6" cols="12">
                      <label>ยอดสั่งซื้อขั้นต่ำ(บาท)</label>
                      <v-text-field
                        v-model="discount_minimum"
                        outlined
                        dense
                        hide-details
                        label="ยอดสั่งซื้อขั้นต่ำ"
                        class="mt-4"
                      ></v-text-field>
                    </v-col>
                    <!-- เริ่มกิจกรรม -->
                    <v-col md="6" cols="12">
                      <label>เริ่มกิจกรรม</label>
                      <v-text-field
                        v-model="startCampaign"
                        outlined
                        dense
                        hide-details
                        type="date"
                        label="เริ่มกิจกรรม"
                        class="mt-2"
                      ></v-text-field>
                    </v-col>
                    <!-- สิ้นสุดกิจกรรม -->
                    <v-col md="6" cols="12">
                      <label>สิ้นสุดกิจกรรม</label>
                      <v-text-field
                        v-model="endCampaign"
                        outlined
                        dense
                        hide-details
                        type="date"
                        label="สิ้นสุดกิจกรรม"
                        class="mt-2"
                      ></v-text-field>
                    </v-col>
                    <!-- จำกัดจำนวนการใช้คูปอง -->
                    <v-col md="6" cols="12">
                      <label>จำกัดจำนวนการใช้คูปอง</label>
                      <v-text-field
                        v-model="maxUseCoupon"
                        outlined
                        dense
                        hide-details
                        label="จำกัดจำนวนการใช้คูปอง"
                        class="mt-2"
                      ></v-text-field>
                    </v-col>
                    <!-- หมายเหตุ -->
                    <v-col md="6" cols="12">
                      <label>หมายเหตุ</label>
                      <v-textarea
                        label="หมายเหตุ"
                        auto-grow
                        outlined
                        rows="1"
                        row-height="15"
                        class="mt-2"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn color="primary" @click="dialog.value = false">บันทึกคูปอง</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>
      </div>
      <hr class="mt-5 mb-5" />
      <!-- ตารางคูปองส่วนลด -->
      <v-data-table :headers="headers" :items="desserts" :items-per-page="10" class="elevation-1"></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  setup() {
    const randomNumber = ''

    return {
      randomNumber,
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'คูปองส่วนลด',
          align: 'start',
          sortable: false,
          value: 'coupon_discount',
        },
        { text: 'ส่วนลด', value: 'discount' },
        { text: 'ยอดสั่งซื้อขั้้นต่ำ', value: 'minimum' },
        { text: 'ระยะเวลาคูปอง', value: 'timeline' },
        { text: 'จำนวนการใช้คูปอง', value: 'quantity' },
        { text: 'สถานะคูปอง', value: 'status' },
      ],
      desserts: [
        {
          id: 'wdyw1',
          coupon_discount: 'Membership',
          discount: '20%',
          minimum: '100 บาท',
          timeline: '1 เดือน',
          quantity: '5 ครั้ง',
          status: 'พร้อมใช้งาน',
        },
        {
          id: 'wdyw2',
          coupon_discount: 'MidYear',
          discount: '50%',
          minimum: '0 บาท',
          timeline: '1 เดือน',
          quantity: '1 ครั้ง',
          status: 'พร้อมใช้งาน',
        },
        {
          id: 'wdyw1',
          coupon_discount: 'Membership',
          discount: '20%',
          minimum: '100 บาท',
          timeline: '1 เดือน',
          quantity: '5 ครั้ง',
          status: 'พร้อมใช้งาน',
        },
        {
          id: 'wdyw2',
          coupon_discount: 'MidYear',
          discount: '50%',
          minimum: '0 บาท',
          timeline: '1 เดือน',
          quantity: '1 ครั้ง',
          status: 'พร้อมใช้งาน',
        },
        {
          id: 'wdyw1',
          coupon_discount: 'Membership',
          discount: '20%',
          minimum: '100 บาท',
          timeline: '1 เดือน',
          quantity: '5 ครั้ง',
          status: 'พร้อมใช้งาน',
        },
        {
          id: 'wdyw2',
          coupon_discount: 'MidYear',
          discount: '50%',
          minimum: '0 บาท',
          timeline: '1 เดือน',
          quantity: '1 ครั้ง',
          status: 'พร้อมใช้งาน',
        },
        {
          id: 'wdyw1',
          coupon_discount: 'Membership',
          discount: '20%',
          minimum: '100 บาท',
          timeline: '1 เดือน',
          quantity: '5 ครั้ง',
          status: 'พร้อมใช้งาน',
        },
        {
          id: 'wdyw2',
          coupon_discount: 'MidYear',
          discount: '50%',
          minimum: '0 บาท',
          timeline: '1 เดือน',
          quantity: '1 ครั้ง',
          status: 'พร้อมใช้งาน',
        },
        {
          id: 'wdyw1',
          coupon_discount: 'Membership',
          discount: '20%',
          minimum: '100 บาท',
          timeline: '1 เดือน',
          quantity: '5 ครั้ง',
          status: 'พร้อมใช้งาน',
        },
        {
          id: 'wdyw2',
          coupon_discount: 'MidYear',
          discount: '50%',
          minimum: '0 บาท',
          timeline: '1 เดือน',
          quantity: '1 ครั้ง',
          status: 'พร้อมใช้งาน',
        },
        {
          id: 'wdyw1',
          coupon_discount: 'Membership',
          discount: '20%',
          minimum: '100 บาท',
          timeline: '1 เดือน',
          quantity: '5 ครั้ง',
          status: 'พร้อมใช้งาน',
        },
        {
          id: 'wdyw2',
          coupon_discount: 'MidYear',
          discount: '50%',
          minimum: '0 บาท',
          timeline: '1 เดือน',
          quantity: '1 ครั้ง',
          status: 'พร้อมใช้งาน',
        },
      ],
    }
  },

  methods: {
    funtionRamdomNumber() {
      this.randomNumber = (Math.random() * 100000).toFixed(0)
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.wapper_contentAddCoupon {
  display: flex;
  justify-content: space-between;
}
.wrapper_contentRandom {
  display: flex;
  justify-content: space-between;
}
.clickRamdomCode {
  cursor: pointer;
  color: #8dbc89;
}
.text_discount {
  margin-right: 1rem;
}
</style>
